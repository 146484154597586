import axios, { AxiosResponse, AxiosError } from 'axios';
import store from '@/store';
import { showError, showWarning, showSuccess } from '../notification';

// eslint-disable-next-lines
axios.interceptors.request.use((config: any) => {
  store.dispatch('loading/toggleLoading', true);
  const params = new URLSearchParams(config.params);
  params.append('origin', 'Management');
  config.params = params;
  return config;
  // eslint-disable-next-line
}, (error: any) => Promise.reject(error));

function handleRequestResponseMessages (messages: Record<string, any>): void {
  if (messages.errors) {
    messages.errors.forEach((m: string) => {
      showError({ message: m });
    });
  }
  if (messages.warnings) {
    messages.warnings.forEach((m: string) => {
      showWarning({ message: m });
    });
  }
  if (messages.informations) {
    messages.informations.forEach((m: string) => {
      showSuccess({ message: m });
    });
  }
  if (typeof messages === 'string') {
    showError({ message: messages });
  }
}

axios.interceptors.response.use((response: AxiosResponse) => {
  store.dispatch('loading/toggleLoading', false);
  if (response.data?.messages) {
    handleRequestResponseMessages(response.data.messages);
  }

  return response;
}, (error: AxiosError) => {
  store.dispatch('loading/toggleLoading', false);
  console.log('request error', error); /* eslint-disable-line */
  console.log('request error response', error.response); /* eslint-disable-line */
  if (error.response?.data.custom) {
    throw (error.response?.data.custom); /* eslint-disable-line */
  }
  if (error.request.status === 401) {
    if (process.env.VUE_APP_USE_PLATFORM_LOGIN_REDIRECT === 'true') {
      window.location.assign(`${process.env.VUE_APP_SIGN_IN_URL}?redirectUrl=${btoa(window.location.href)}&origin=Management`);
    } else {
      store.dispatch('auth/toggleSignInPageVisibility', { value: true });
    }
  }
  if (error.response?.data?.messages || error.response?.data?.error) {
    handleRequestResponseMessages(error.response.data.messages || error.response?.data?.error);
  }
  throw new Error(error.message);
});
