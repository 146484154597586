<template>
  <v-app>
    <loading />
    <v-main>
      <v-dialog v-if='getSignInPageVisibility' :value='true' fullscreen persistent>
        <sign-in />
      </v-dialog>

      <div>
        <app-bar
          v-if='($router.currentRoute.name === route.SIGN_IN.name ? false
            : !getSignInPageVisibility ) && !isCurrentPageReport($router.currentRoute.name)'
          @customer-change='key++'
        />
        <app-bar-drawer
          v-if='$router.currentRoute.name === route.SIGN_IN.name ? false
            : !getSignInPageVisibility && !isCurrentPageReport($router.currentRoute.name)'
        />
        <router-view v-show='!($router.currentRoute.name === route.SIGN_IN.name ? false : getSignInPageVisibility)' :key='key' />
      </div>
    </v-main>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Route } from './router';

  export default {
    name: 'App',
    components: {
      AppBar: () => import('./components/Core/AppBar'),
      AppBarDrawer: () => import('./components/Core/AppBarDrawer'),
      Loading: () => import('./components/Loading/Loading'),
      SignIn: () => import('./pages/Auth/Auth'),
    },
    data () {
      return {
        reportRoutes: [],
        key: 0,
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getSignInPageVisibility',
      ]),
      route: function () {
        return Route;
      },
    },
    created: function () {
      this.reportRoutes = Object.values(Route).filter((route) => route.isReport);
    },
    mounted: function () {
      this.$store.dispatch('auth/getLoggedUser');
    },
    methods: {
      isCurrentPageReport (page) {
        return this.reportRoutes.find((r) => r.name.indexOf(page) >= 0);
      },
    },
  };
</script>

<style lang='scss' scoped>
  .app-color {
    color: var(--v-primary-base) !important;
  }
  ::v-deep.v-application {
    background-color: var(--v-background-base) !important;
  }
</style>
